<template>
  <div class="wrap">
    <Sticky>
      <Navbar :title="$t('page.member')" :border="false" />
      <div class="search_wrap">
        <div class="input">
          <Icon name="search" color="rgba(255,255,255,.6)" />
          <input v-model="searchVal" type="text" :placeholder="$t('placeholder.member')">
        </div>
        <div class="btn_wrap" @click="handleSearch">
          <Icon name="search" color="#eee" />
          <div class="text">{{ $t("btn.search") }}</div>
        </div>
      </div>
      <div class="sort_wrap">
        <div class="btn" @click="changeSort(item.value)" v-for="item, index in sorts" :key="index">
          <i class="iconfont" :class="getSortIcon(item)"></i>
          <span>{{ $t(`btn.sort.${item.title}`) }}</span>
        </div>
      </div>
    </Sticky>
    <List v-model="loading" :loading-text="$t('Hint.loading')" :finished="!hasMore" :finished-text="$t('Hint.notMore')"
      @load="getList">
      <ListItem v-for="item, index in listData" :key="index" :data="item" />
    </List>
  </div>
</template>

<script>
import { Sticky, Icon, List, Cell, Toast, Image as VanImage } from 'vant';
import Navbar from '@/components/JNav';
import { get, post } from '@/utils/newRequest'
import ListItem from "./components/listItem.vue"
export default {
  components: { Sticky, Icon, Navbar, List, Cell, VanImage, ListItem },
  data() {
    this.sorts = [{
      title: 'level',
      value: 'fromlevel'
    }, {
      title: 'indate',
      value: 'regdate'
    }]
    return {
      page: 0,
      listData: [],
      hasMore: true,
      showToast: true,
      loading: false,
      sortNow: "",
      sortType: "",
      searchVal: ""
    }
  },
  created() {
    this.getList();
  },
  methods: {
    changeSortType(type) {
      if (typeof type === "string") {
        this.sortType = type
        return;
      }
      switch (this.sortType) {
        case "":
          this.sortType = "desc";
          break;
        case "desc":
          this.sortType = "asc";
          break;
        case "asc":
          this.sortType = ""
        default:
          break;
      }
    },
    async getList() {
      this.loading = true;
      this.page += 1;
      if (this.page === 1) {
        this.showToast = true;
      } else {
        this.showToast = false;
      }
      try {
        const { currentPage, lists, hasMorePages } = await post("/users/ref/users", { page: this.page, sortby: this.sortNow, sort: this.sortType, key: this.searchVal }, { showToast: this.showToast });
        this.hasMore = hasMorePages;
        this.loading = false;
        if (currentPage == 1) {
          this.listData = lists;
          return
        }
        this.listData = [...this.listData, ...lists]
      } catch (error) {
        console.log(error)
      }
    },
    copy(text = "") {
      this.$copyText(text).then((e) => {
        Toast("Copy successfully");
      });
    },
    changeSort(val) {
      this.sortNow = val;
      if (this.sortNow === val) {
        this.changeSortType();
        this.page = 0;
        this.getList();
        return
      }
      this.changeSortType("desc");
      this.page = 0;
      this.getList();
    },
    getSortIcon(item) {
      if (this.sortNow !== item.value) return 'icon-igw-l-screen'
      switch (this.sortType) {
        case 'desc':
          return "icon-xiangxia"
        case 'asc':
          return "icon-xiangshang"
        default:
          return "icon-igw-l-screen"
      }
    },
    handleSearch() {
      this.page = 0;
      this.getList();
    }
  }
}
</script>

<style lang="less" scoped>
.flex {
  display: flex;
}

.search_wrap {
  margin-top: -1px;
  .flex();
  padding: calc(10rem / 16) calc(12rem / 16);
  @itemHeight: calc(36rem / 16);

  .input {
    height: @itemHeight;
    flex: 1;
    color: var(--textColor);
    .flex();
    align-items: center;
    border-radius: 10px;
    padding: 0 calc(6rem / 16);
    background: var(--light);
    font-size: 16px;

    >input {
      font-size: calc(17rem / 16);
      background: transparent;
      border: none;
    }

    input::placeholder {
      color: var(--colors);
    }
  }

  .btn_wrap {
    .flex();
    margin-left: 8px;
    flex: 1;
    justify-content: center;
    align-items: center;
    background: var(--red);
    padding: calc(6rem / 16) calc(8rem / 16);
    border-radius: calc(12rem / 16);
    font-size: calc(16rem / 16);

    .btn {
      color: var(--light);
      height: @itemHeight;
      line-height: @itemHeight;
      .flex();
    }

    .text {
      margin-left: 6px;
      color: var(--light);
      white-space: nowrap;
    }

  }
}

.sort_wrap {
  padding: 6px 8px 12px 0;
  @height: calc(30rem / 16);
  .flex();

  .btn {
    border: 1px solid var(--gray1);
    line-height: @height;
    padding: 0 5px;
    margin-left: 8px;
    color: var(--gray1);
    border-radius: 4px;
    .flex();
    align-items: center;

    .iconfont {
      font-size: 20px;
      margin-right: 2px;
    }
  }
}

.list_item_wrap {
  .top_bar {
    border-bottom: 1px solid var(--gray1);
    margin-top: calc(12rem / 16);

    .left_wrap {
      .flex();
      align-items: center;

      .copy_wrap {
        border: 1px dashed var(--theme);
        padding: 2px 10px;
        align-items: center;
        .flex();
        align-items: center;
        margin-left: 5px;
        border-radius: 4px;

        .copy {
          font-size: 24px;
          color: var(--theme);
          margin-left: 8px;
        }
      }
    }
  }

  .content_bar {
    /deep/ .van-cell__value {
      .flex();
    }

    .left {
      width: 36px;
      height: 36px;
      margin: 10px 20px 2px 0;
    }

    .right {
      >p {
        font-size: calc(14rem / 16);
        color: var(--textColor);

        >span {
          color: var(--theme);
        }
      }
    }
  }

  .bottom_bar {
    border-top: 1px solid #eee;

    .to_details {
      color: var(--theme);
    }

    .title_style {
      color: #999;
    }
  }
}
</style>